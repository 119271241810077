export default defineNuxtPlugin(() => {
  return {
    provide: {
      urlImage: 'https://almacenamientoefici.blob.core.windows.net/sftp-efici/',
      endpoint: {
        dashboard: {
          sales: { url: 'dashboard/sales', metodo: 'POST' },
          RevenueAnalytics: { url: 'dashboard/RevenueAnalytics', metodo: 'POST' },
          SalesAnalytics: { url: 'dashboard/SalesAnalytics', metodo: 'POST' },
        },
        types: {
          getAll: { url: 'types/get/', metodo: 'GET' },
          new: { url: 'types/create/', metodo: 'POST' },
        },
        countries: {
          new: { url: 'countries/new/', metodo: 'POST' },
          getAll: { url: 'countries/get/', metodo: 'GET' },
        },
        departaments: {
          new: { url: 'departaments/new/', metodo: 'POST' },
          getAll: { url: 'departaments/get/', metodo: 'GET' },
        },
        cities: {
          getAll: { url: 'cities/get/', metodo: 'GET' },
          new: { url: 'cities/new/', metodo: 'POST' },
          delete: { url: 'cities/delete/', metodo: 'DELETE' },
        },
        payments: {
          getAll: { url: 'payments/getPayMethod/', metodo: 'GET' },
          getPlace: { url: 'payments/getAllPaymentPlace/', metodo: 'POST' },
          new: { url: 'payments/newPayMethod/', metodo: 'POST' },
          edit: { url: 'payments/updatePayMethod/', metodo: 'PATCH' },
          newPaymentPlace: { url: 'payments/newPayMethodPlace/', metodo: 'POST' },
          editPaymentPlace: { url: 'payments/updatePayMethodPlace/', metodo: 'PATCH' },
          uploadP: { url: 'image/upload/', metodo: 'POST' },
        },
        users: {
          new: { url: 'users/new/', metodo: 'POST' },
          edit: { url: 'users/edit/', metodo: 'PATCH' },
          getAll: { url: 'users/get/', metodo: 'GET' },
          usersByPlace: { url: 'places/users/', metodo: 'POST' },
          userPlacer: { url: '/users/', metodo: 'POST' },
        },
        typeDocs: {
          getAll: { url: 'typeDocs/get/', metodo: 'GET' },
          new: { url: 'typeDocs/new/', metodo: 'POST' },
          edit: { url: 'typeDocs/edit/', metodo: 'PATCH' },
        },
        roles: {
          getAll: { url: 'roleUsers/get/', metodo: 'GET' },
          getName: { url: 'modulesAuth', metodo: 'GET' },
          getNameUser: { url: 'modulesAuth/', metodo: 'GET' },
          new: { url: 'roleUsers/new/', metodo: 'POST' },
          edit: { url: 'roleUsers/update/', metodo: 'PATCH' },
        },
        rolesAdmin: {
          getAll: { url: 'rolls/get/', metodo: 'GET' },
          new: { url: 'rolls/new/', metodo: 'POST' },
          edit: { url: 'rolls/edit/', metodo: 'PATCH' },
        },
        modules: {
          getAll: { url: 'modules/get/', metodo: 'GET' },
          getByPlace: { url: 'places/getModules/', metodo: 'POST' },
          update: { url: 'places/updateModule/', metodo: 'POST' },
          getId: { url: 'modules/get/', metodo: 'POST' },
          new: { url: 'modules/new/', metodo: 'POST' },
          edit: { url: 'modules/edit/', metodo: 'PATCH' },
          register: { url: 'places/registerModule/', metodo: 'POST' },
          uploadImg: { url: 'image/upload/', metodo: 'POST' },
        },
        // Endpoint banners
        banners: {
          getBannerPlace: { url: 'banners/getPlaceId/', metodo: 'POST' },
          getAll: { url: 'banners/get/', metodo: 'GET' },
          new: { url: 'banners/new/', metodo: 'POST' },
          edit: { url: 'banners/update/', metodo: 'PATCH' },
          delete: { url: 'banners/delete/', metodo: 'DELETE' },
          upload: { url: 'image/upload/', metodo: 'POST' },
        },
        // Endpoint products
        products: {
          // getProducts necesita el atributo /:id del place
          new: { url: 'products/new/', metodo: 'POST' },
          getCategories: { url: 'places/getCategories/', metodo: 'GET' },
          updateCategories: { url: 'places/upOrderCategories/', metodo: 'PATCH' },
          getAll: { url: 'places/getProducts/', metodo: 'GET' },
          edit: { url: 'products/update/', metodo: 'PATCH' },
        },
        places: {
          // getProducts necesita el atributo /:id del place
          getAll: { url: 'places/get/', metodo: 'GET' },
          getId: { url: 'places/get/', metodo: 'POST' },
          getUUID: { url: 'places/get/uuid/', metodo: 'POST' },
          new: { url: 'places/newPlaceUser/', metodo: 'POST' },
          updateHour: { url: 'places/editHour/', metodo: 'PATCH' },
          uploadimg: { url: 'image/upload/', metodo: 'POST' },
          uploadimgUrl: { url: 'image/uploadUrl/', metodo: 'POST' },
          edit: { url: 'places/edit/', metodo: 'PATCH' },
          editStatus: { url: 'places/editStatus/', metodo: 'PATCH' },
          editDomicile: { url: 'places/editDomicile/', metodo: 'PATCH' },
          usersByPlace: { url: 'places/users/', metodo: 'POST' },
          userPlacer: { url: 'users/', metodo: 'POST' },
          updateTables: { url: 'places/editTables/', metodo: 'PATCH' },
        },
        business: {
          getAll: { url: 'customers/business', metodo: 'POST' },
          vinculedCustomer: { url: 'business/vinculeCustomer', metodo: 'POST' },
        },
        paymentPlace: {
          getAll: { url: 'payments/getAllPaymentPlace/', metodo: 'POST' },
        },
        plans: {
          new: { url: 'plans', metodo: 'POST' },
          update: { url: 'plans/', metodo: 'PATCH' },
        },
        runners: {
          getAll: { url: 'Efici/runners/get', metodo: 'POST' },
          getId: { url: 'Efici/runners/getId', metodo: 'POST' },
          getBusiness: { url: 'business/getPlans', metodo: 'POST' },
          new: { url: 'Efici/runners', metodo: 'POST' },
          getCustomer: { url: 'customers/getCustomer', metodo: 'POST' },
          createCustomer: { url: 'customers/', metodo: 'POST' },
          newVehicle: { url: 'Efici/vehicles/new/', metodo: 'POST' },
          updateBusiness: { url: 'Efici/runners/updateBusiness', metodo: 'POST' },
          getCustomers: { url: 'customers/get/', metodo: 'POST' },
          getVehicles: { url: 'Efici/runners/vehicule/', metodo: 'GET' },
          cost: { url: 'runner/business/cost', metodo: 'POST' },
          vincule: { url: 'business/vinculeCustomer', metodo: 'POST' },
        },
        customers: {
          getAll: { url: 'customer/get/', metodo: 'GET' },
          new: { url: 'customer/create', metodo: 'POST' },
          newAddress: { url: 'addresses/new', metodo: 'POST' },
          edit: { url: 'customers/update/', metodo: 'PATCH' },
          editDomicile: { url: 'places/editDomicile/', metodo: 'PATCH' },
          usersByPlace: { url: 'places/users/', metodo: 'POST' },
          userPlacer: { url: 'users/', metodo: 'POST' },
        },
        // Endpoint categories
        categories: {
          new: { url: 'categories/new/', metodo: 'POST' },
          edit: { url: 'categories/update/', metodo: 'PATCH' },
          changeOrder: { url: 'categories/upOrderCategoryProducts', metodo: 'PATCH' },
          editStatus: { url: 'categories/updateStatus/', metodo: 'PATCH' },
          getAll: { url: 'categories/get/', metodo: 'GET' },
          getOfPlace: { url: 'categories/getOfPlace/', metodo: 'POST' },
          upload: { url: 'image/upload/', metodo: 'POST' },
          registerToPlace: { url: 'categories/addCategoryToPlace', metodo: 'POST' },
          deleteToPlace: { url: 'categories/delCategoryToPlace', metodo: 'DELETE' },
        },
        chat: {
          getChatHistory: { url: 'chat/orderId', metodo: 'POST' },
        },
        version: {
          new: { url: 'market/create/', metodo: 'POST' },
          edit: { url: 'market/update/', metodo: 'PATCH' },
          getAll: { url: 'market/info/', metodo: 'GET' },
          delete: { url: 'market/delete/', metodo: 'DELETE' },
          upload: { url: 'image/upload/', metodo: 'POST' },
        },
        // Endpoint orders
        orders: {
          getAll: { url: 'orders/get', metodo: 'POST' },
          getByPlace: { url: 'places/orders/', metodo: 'POST' },
          editByPlace: { url: 'orders/update/', metodo: 'PATCH' },
          modifyProducts: { url: 'orders/modifyProducts/', metodo: 'PATCH' },
          getById: { url: 'orders/get/', metodo: 'POST' },
          getStatus: { url: 'orders/getStatusbyOrder/', metodo: 'POST' },
          addStatus: { url: 'status/addStatusToOrder/', metodo: 'POST' },
          new: { url: 'orders/new/', metodo: 'POST' },
          newV2: { url: 'orders/newV2/', metodo: 'POST' },
          newUpdate: { url: 'orders/newUpdate/', metodo: 'PATCH' },
          newUpdateOrder: { url: 'orders/newUpdateOrder/', metodo: 'PATCH' },
        },
        coupons: {
          getAll: { url: 'coupons/get/', metodo: 'GET' },
          new: { url: 'coupons/new', metodo: 'POST' },
          edit: { url: 'coupons/update/', metodo: 'PATCH' },
          editActive: { url: 'coupons/active/', metodo: 'PATCH' },
          usersByPlace: { url: 'coupons/get/', metodo: 'POST' },
        },
        travels: {
          getAll: { url: 'Efici/travels/', metodo: 'POST' },
          getId: { url: 'Efici/travels/', metodo: 'GET' },
          new: { url: 'travels/', metodo: 'POST' },
          cost: { url: 'travels/cost', metodo: 'POST' },
          status: { url: 'Efici/travels/travelStatus/', metodo: 'GET' },
          addStatus: { url: 'Efici/travels/addStatus', metodo: 'POST' },
          vinculeRunner: { url: 'Efici/travels/runner/', metodo: 'POST' },
        },
        // Endpoint status
        status: {
          getAll: { url: 'status/get/', metodo: 'GET' },
        },
        resume: {
          getPlace: { url: 'paymentSummary/getPlace/', metodo: 'GET' },
          getCustomers: { url: 'runner/business/customers', metodo: 'POST' },
          new: { url: 'paymentSummary/create', metodo: 'POST' },
          newRunner: { url: 'paymentSummary/createRunner', metodo: 'POST' },
          getAll: { url: 'paymentSummary/get/', metodo: 'GET' },
          update: { url: 'paymentSummary/update/', metodo: 'PATCH' },
          updateRunner: { url: 'paymentSummary/updateRunner/', metodo: 'PATCH' },
          delete: { url: 'paymentSummary/delete/', metodo: 'DELETE' },
          deleteRunner: { url: 'paymentSummary/deleteRunner/', metodo: 'DELETE' },
          getRunner: { url: 'paymentSummary/getRunner/', metodo: 'GET' },
        },
        reports: {
          activities: { url: 'reports/reportActivities', metodo: 'POST' },
          getAll: { url: 'modules/get/', metodo: 'GET' },
          customer: { url: 'admin/customers', metodo: 'POST' },
        },
        statusRunner: {
          getAll: { url: 'Efici/status/', metodo: 'GET' },
        },
        legalCommerce: {
          save: { url: 'legal/create/', metodo: 'POST' },
          get: { url: 'legal/get/', metodo: 'GET' },
        },
        invoice: {
          create: { url: 'invoices/create/', metodo: 'POST' },
          get: { url: 'invoices/get/', metodo: 'GET' },
        },
        taxes: {
          create: { url: 'taxes/create/', metodo: 'POST' },
          get: { url: 'taxes/get/', metodo: 'GET' },
          edit: { url: 'taxes/update/', metodo: 'PATCH' },
        },
        wholeSale: {
          getAll: { url: 'places/getWholesale', metodo: 'POST' },
        },
        runner: {
          getAll: { url: 'Efici/business/runners', metodo: 'POST' },
        },
      },
    },
  }
})
